import { client } from '@/services/api-client'
import { VehicleWithdrawalReason } from '@/types/VehicleWithdrawalReason'
import { gql } from 'graphql-request'

export const getVehicleWithdrawalReason = (): Promise<
  VehicleWithdrawalReason[]
> =>
  client
    .request<{
      vehicle_withdrawal_reason: VehicleWithdrawalReason[]
    }>(
      gql`
        query getVehicleWithdrawalReason {
          vehicle_withdrawal_reason {
            uuid
            name
          }
        }
      `
    )
    .then((data) => data.vehicle_withdrawal_reason)
