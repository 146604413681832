import { client } from '@/services/api-client'
import { VehicleWithdrawalDepot } from '@/types/VehicleWithdrawalDepot'
import { gql } from 'graphql-request'

export const getVehicleWithdrawalDepot = (): Promise<
  VehicleWithdrawalDepot[]
> =>
  client
    .request<{
      vehicle_withdrawal_depot: VehicleWithdrawalDepot[]
    }>(
      gql`
        query getVehicleWithdrawalDepot {
          vehicle_withdrawal_depot {
            uuid
            name
          }
        }
      `
    )
    .then((data) => data.vehicle_withdrawal_depot)
